import * as React from 'react';
import * as Sentry from '@sentry/browser';

type Props = React.PropsWithChildren<unknown>;
type State = {
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

export class ErrorBoundary extends React.Component<Props, State> {

  // note: this is REQUIRED for having another render()-call after componentDidCatch()
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    try {
      Sentry.captureException(error);
    } catch (e) {
      // do nothing
    }

    console.log('ErrorBoundary caught Exception:', { error, errorInfo });
    this.setState({
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state || {};

    if (error) {
      console.log('ErrorBoundary render(): ', { error, errorInfo });
      return (
        <>
          <div>{`${error.name}: ${error.message}`}</div>
          <div style={{ fontSize: '0.8rem', whiteSpace: 'pre' }}>{errorInfo?.componentStack.trim()}</div>
        </>
      );
    }

    return this.props.children;
  }
}
