import { Theme } from '@screencloud/screencloud-ui-components';
import styled, { createGlobalStyle } from 'styled-components';

// tslint:disable-next-line:variable-name
export const GlobalStyles = createGlobalStyle`
  body {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: ${Theme.color.light};
    height: auto !important;
    min-height: 100%;
  }

    /* Loader custom style as we have zenDesk widget */
  .ui.loader.active {
    bottom: -1px;
    right: 120px;
  }

  @media only screen and (max-width: ${Theme.break.mediumAlt}) {
    #launcher {
      display: none;
    }

    .ui.loader.active {

      bottom: 0;
      right: 0;
    }
  }

  @media only screen and (max-height: 414px) and (max-width: 991px){
    .App:after {
      display: flex;
      content: 'Nothing to see hear, please rotate your device!';
      width: 100%;
      position: absolute;
      z-index: 3000;
      height: 100%;
      top: 0;
      color: ${Theme.color.white};
      font-size: 3.2em;
      font-weight: 900;
      justify-content: center;
      align-items: center;
      background: ${Theme.color.darker} url(https://media.giphy.com/media/nRnY3U7u4AvXq/giphy.gif) no-repeat 50%;
      background-size: cover;
      text-shadow: 0 0px 10px rgba(0,0,0,.2);
      text-align: center;
      line-height: 1.2;
      padding: 50px;
    }
  }

  .md-fullscreen {
    z-index: 11;
  }

  .expandable-open {
    z-index: 20;
  }

  .App.Admin {
    min-height: 100vh;
    background: ${Theme.color.light};

    .container {
      max-width: 960px;
      margin: 0 auto 20px;
      padding: 40px 30px;
      background: ${Theme.color.white};
      ${Theme.default.borderRadius};
      border: solid 1px ${Theme.color.lightGrey};
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      &:last-child { margin-bottom: 0 }
      &.relative { position: relative }
      &.danger {
        border-color: ${Theme.color.error};
        .section {
          padding: 12px 30px;
          border-bottom: solid 1px ${Theme.color.lightGrey};
          align-items: flex-start;
          display: flex;
          &:first-child {
            padding-top: 0;
            border-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
          }
          &-desc,
          &-action {
            flex-basis: 0;
          }
          &-desc {
            flex-grow: 1;
            .label {
              margin: 0 0 4px;
              font-size: 16px;
              font-weight: 700;
            }
          }
          &-action {
            margin-left: 12px;
            flex-grow: 0;
          }
        }
      }
      &.no-horizontal-padding {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.no-vertical-padding {
        padding-left: 0;
        padding-right: 0;
        > div > .title {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    //
    // Responsive
    //
    @media (max-width: ${Theme.break.mediumAlt}) {}
    @media (max-width: ${Theme.break.small}) {
      .container {
        padding: 18px ${Theme.admin.mobileVerticalPadding};
        &.no-vertical-padding > div > .title {
          padding-left: ${Theme.admin.mobileVerticalPadding};
          padding-right: ${Theme.admin.mobileVerticalPadding};
        }
        &.danger {
          .section {
            padding-left: ${Theme.admin.mobileVerticalPadding};
            padding-right: ${Theme.admin.mobileVerticalPadding};
            align-items: normal;
            flex-direction: column;
            &-desc,
            &-action {
              flex-grow: 1;
              flex-basis: auto;
            }
            &-desc {
              margin: 0 0 12px;
            }
            &-action {
              margin-left: 0;
              .button {
                width: 100%;
              }
            }
          }
        }
      }
    }
    @media (max-width: ${Theme.break.tinyAlt}) {}
  }

  .app-config-modal {
    .app-config-header {
      .close-btn {
        width: 70px;
        height: 70px;
        background: ${Theme.color.darker};
        margin: 0;
        z-index: 11;
        border-radius: 0;

        :hover {
          background: ${Theme.color.black};
        }

        i {
          background-color: ${Theme.color.white};
          margin-top: 6px;
        }
      }
    }
  }
  
  table.migration-table {
    td {
      vertical-align: top;    
    }
  }

  .migration-menu-container {
    width: 100%;
  }

  .migration-menu {
     display: flex !important;
    .button, .menu-item {
      min-width: 200px;
      height: 64px;
      margin: 0px;
      border-radius: 0px;
      padding: 20px;
      background-color: #1b1c1d;
      color: white;
      font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    }
    .menu-item {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex: 1;
      background-color: #FFFFFF;
      color: #DDDDDD;
      font-weight: bold;
            
      .order {
        font-size: 32px;
        margin-right: 15px;
      }

      .step-name {
        font-size: 16px;
        display: flex;
        flex-direction: column;

        .completed {
          font-size: 12px;
        }
      }
    }
    .current-step {
      background-color: #1F2027;
      color: #FFFFFF;
    }    
    .menu-item:first-of-type {
      border-radius: 6px 0px 0px 6px;
    }
    .menu-item:last-of-type {
      border-radius: 0px 6px 6px 0px;
    }    
    .menu-item.completed {
      background-color: #2FB457;
      color: #FFFFFF;
    }
  }

  .button.action, .button.action:hover {
    background-color: #FECF00;
    color: black;
  }

  .action-panel {
    margin: 20px 0px;
    display: flex;
  }

  .action-column {
    padding-left: 25px;
  }

  .info-box {
    background-color: #FEF9E3;
    border-color: #FECF00;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px;
    border-style: solid;
  }

  .review-text {
    h3 {
      margin: 5px 0px;
    }
    width: 70%;
  }

  .migration-completed {
    h3 {
      margin: 5px 0px;
    }
    width: 100%;
  }
`;
export const AdminStyled = styled.div`
  padding: ${Theme.admin.desktopHorizontalPadding} ${Theme.admin.desktopVerticalPadding} 60px;
  .searchfield {
    margin: 0 0 ${Theme.admin.desktopVerticalMargin};
  }

  //
  // Responsive
  //
  @media (max-width: ${Theme.break.tinyAlt}) {
    padding: ${Theme.admin.mobileHorizontalPadding} ${Theme.admin.mobileVerticalPadding} 90px;
    .searchfield {
      margin: 0 0 ${Theme.admin.mobileVerticalMargin};
    }
  }
`;
