import { appConfig } from '../appConfig';

export function getRegions(): { region: string; url: string; isCurrent?: boolean; }[] {
  const { hostname } = window.location;

  // special case local development
  if (hostname.includes('.dev.next.sc')) {
    return [
      { region: '', url: appConfig.baseUrl, isCurrent: true },
      // { region: 'fake', url: appConfig.baseUrl },
    ];
  }

  // find current region
  const knownRegions = ['eu', 'us'];
  const currentRegion = knownRegions
    .find((r) => hostname.includes(`.${r}.`));

  return knownRegions
    .map((region) => ({
      isCurrent: currentRegion === region,
      url: window.location.origin
        .replace(`.${currentRegion}.`, `.${region}.`),
      region,
    }));
}
