import { Loader } from '@screencloud/screencloud-ui-components';
import * as React from 'react';
import { Router, View } from 'react-navi';
import { appConfig } from './appConfig';
import { GlobalStyles } from './components/GlobalStyles/GlobalStyles';
import { Layout } from './components/Layout/Layout';
import routes from './pages/routes';
import { useSession } from './state/session/useSession';

export function App() {
  const session = useSession();

  // loggedIn using google, does not imply access!
  const loggedIn = session.user
    && (
      (session.user.claims.connection === 'google-oauth2' && session.user.claims.provider === 'google-oauth2')
      || (session.user.claims.connection === 'google' && session.user.claims.provider === 'google')
    );

  if (session.error) {
    const claims = session.user?.claims;

    return (
      <>
        <div>{session.error.message || 'unknown session error occurred'}</div>
        {(claims
          ? (
            <div>Current user: {claims.email} ({claims.provider})</div>
          )
          : (
            <div>
              <a href={`${appConfig.auth.frontend}/mc`}>Login required</a>
            </div>
          )
        )}
      </>
    );
  } else if (!session.initialized || session.loading) {
    return <Loader active>Authenticating</Loader>;
  } else if (!loggedIn) {
    return (
      <>
        <div><a href={`${appConfig.auth.frontend}/mc`}>Login required</a></div>
      </>
    );
  }

  return (
    <>
      <GlobalStyles />
      <Router routes={routes}>
        <Layout>
          <React.Suspense fallback={null}>
            <View />
          </React.Suspense>
        </Layout>
      </Router>
    </>
  );
}
