import { lazy, mount, redirect } from 'navi';

export default mount({
  '*': redirect('/'),
  '/': redirect('/dashboard'),
  '/dashboard': lazy(() => import('./dashboard/routes')),
  '/org': lazy(() => import('./org/routes')),
  '/member': lazy(() => import('./member/routes')),
  '/app': lazy(() => import('./app/routes')),
  '/notify': lazy(() => import('./notify/routes')),
  '/migration': lazy(() => import('./customerMigration/routes')),
  '/maintenance/screen': lazy(() => import('./screen/routes')),
  '/maintenance/feature-flags': lazy(() => import('./featureFlags/routes')),
  '/maintenance/scheduler': lazy(() => import('./cron/routes')),
  '/maintenance/screens-connectivity': lazy(() => import('./screensConnectivity/routes')),
  '/maintenance/screenshot-request': lazy(() => import('./screenshotRequest/routes')),
  '/enterprise/provision': lazy(() => import('./enterprise/routes')),
  '/settings/user': lazy(() => import('./user/routes')),
});
