import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { App } from './App';
import { getApolloClient } from './state/apolloClient';
import { useSession } from './state/session/useSession';

export const appWrapper: { apolloClient?: ApolloClient<NormalizedCacheObject> } = {};

export function AppWrapper(props: React.PropsWithChildren<unknown>) {
  const session = useSession();

  appWrapper.apolloClient = getApolloClient({
    authorizationFn: async () => {
      await session.refresh();
      return session.user && session.user.authorization();
    },
  });

  return (
    <ApolloProvider client={appWrapper.apolloClient}>
      <App />
    </ApolloProvider>
  );
}
