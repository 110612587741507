import { useEffect, useState } from 'react';
import { IEventHandler } from '../../utils/EventEmitter';
import {
  SystemSessionState,
  systemSession,
} from './SystemSession';

/**
 * Hook returning the current SystemSession
 */
export function useSession(): SystemSessionState {
  // ensure this is called at least once
  systemSession.init().then(() => undefined, () => undefined);
  const [session, setSession] = useState<SystemSessionState>(systemSession.toState());

  useEffect(() => {
    const stateChangedHandler: IEventHandler<SystemSessionState> = (event) => {
      // console.log(`useSession received stateChanged`, event);
      setSession(event.data);
    };
    // console.log(`useSession subscribed`);
    systemSession.on('stateChanged', stateChangedHandler);

    // set it here once again, as there's a slim chance of things to get missed.
    setSession(systemSession.toState());

    return () => {
      // console.log(`useSession unsubscribed`);
      systemSession.off('stateChanged', stateChangedHandler);
    };
  }, [session.user && session.user.token]);

  return session;
}
