import { Auth } from '@screencloud/auth-sdk';
import { UUID } from '@screencloud/uuid';
import * as jwtDecode from 'jwt-decode';
import { isEqual } from 'lodash';
import { appConfig } from '../../appConfig';
import { EventEmitter } from '../../utils/EventEmitter';
import { getRegions } from '../../utils/getRegions';
import { shouldRefresh } from './sessionUtils';

export interface SystemJWTClaims {
  // Basic Claims
  exp: number;
  iat: number;
  iss: string;
  aud: string;
  // System Claims
  // eslint-disable-next-line camelcase
  is_owner: boolean;
  email: string;
  // eslint-disable-next-line camelcase
  user_id: UUID;
  // eslint-disable-next-line camelcase
  family_name: string | null;
  // eslint-disable-next-line camelcase
  given_name: string | null;
  provider: string;
  connection: string;
  picture?: string;
}

export interface SystemSessionConfig {
  systemTokenExchangeEndpoint: string;
  autoRefresh?: boolean;
}

export interface SystemSessionState {
  user?: {
    isOwner: boolean;
    token: string;
    authorization: () => string;
    claims: SystemJWTClaims;
  };
  error?: {
    message: string;
  };
  init: () => void;
  initialized: boolean;
  loading: boolean;
  logout: () => void;
  refresh: () => Promise<void>;
  region?: string;
}

export class SystemSession extends EventEmitter<{
  stateChanged: SystemSessionState;
}> {
  protected _initialized: boolean = false;
  protected _loading: boolean = false;
  protected _error?: Readonly<{ message: string }>;
  protected _systemTokenExchangeEndpoint: string;
  protected _auth: Auth;
  protected _data?: Readonly<{ claims: SystemJWTClaims, token: string }>;
  protected _autoRefresh: any;
  protected _lastRefresh: number = 0; // millisecond timestamp
  protected _authToken?: string;

  constructor(config: SystemSessionConfig) {
    super();
    this._systemTokenExchangeEndpoint = config.systemTokenExchangeEndpoint;

    this._auth = new Auth({
      debug: appConfig.auth.debug,
      autoRefresh: appConfig.auth.autoRefreshSession,
      autoSync: appConfig.auth.autoSyncSession,
      service: { url: appConfig.auth.service },
      frontend: { url: appConfig.auth.frontend },
    });
    this.autoRefresh = config.autoRefresh || false;
  }

  get lastRefresh(): number {
    return this._lastRefresh;
  }

  get isOwner() {
    return !!this.claims && this.claims.is_owner;
  }

  get authorization(): string | undefined {
    const token = this.token;
    return token
      ? `Bearer ${token}`
      : undefined;
  }

  get claims() {
    return this._data
      ? this._data.claims
      : undefined;
  }

  get token() {
    return (this._data && this._data.token);
  }

  get autoRefresh(): boolean {
    return !!this._autoRefresh;
  }

  set autoRefresh(v: boolean) {
    if (v && !this._autoRefresh) {
      this._autoRefresh = window.setInterval(async () => {
        // console.log('autoRefresh handler');

        // Returns true in RARE cases, because...
        // ... the ID JWT should outlive the MC JWT anyway
        // ... when ID JWT is refreshed, we trigger a refresh of the MC JWT
        // but in case our MC JWT has a shorter TTL than out ID JWT
        if (shouldRefresh(this.claims?.exp, this.lastRefresh)) {
          try {
            await this.refreshToken();
          } catch (e) {
            const error = e as {message: string};
            this.setError(error);
          }
        }
      }, 10 * 1000);
    } else if (!v && this._autoRefresh) {
      window.clearInterval(this._autoRefresh);
      this._autoRefresh = 0;
    }
  }

  async init() {
    if (this._initialized || this._loading) {
      return;
    }
    try {
      this._loading = true;
      this.trigger('stateChanged', this.toState());

      console.log(`SystemSession.init(): auth.requestToken()`);
      const authSession = await this._auth.get();

      if (!authSession) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('unauthenticated');
      } else {
        const { email, strategy, provider, connection } = authSession.claims;
        console.log(
          `SystemSession.init(): refresh() as ${email} (${strategy}/${provider}/${connection})`,
          authSession.claims,
        );
        this.setToken(await this.fetchSystemToken(authSession.token), true);
        console.log(`SystemSession.init(): done`);
      }
    } catch (e) {
      const error = e as {message: string};
      console.log(`SystemSession.init(): error occurred ${error.message}`);
      this.setError({ message: error.message }, true);
    }

    // subscribe to events
    // ... refresh when refreshed
    this._auth.on('refreshed', (data) => {
      this.refreshToken();
    });
    // ... refresh when loggedIn
    this._auth.on('loggedIn', (data) => {
      this.refreshToken();
    });

    // ... reset when loggedOut
    this._auth.on('loggedOut', (data) => {
      this.resetToken();
    });

    this._loading = false;
    this._initialized = true;
    this.trigger('stateChanged', this.toState());

  }

  logout() {
    this._auth
      .logout()
      .then(({ redirectUrl }) => {
        window.location.href = redirectUrl;
      });
  }

  setError(error: { message: string }, skipTrigger?: boolean) {
    if (this._error && this._error.message === error.message) {
      return;
    }
    this._error = Object.freeze({ message: error.message });
    this._data = undefined;

    if (!skipTrigger) {
      this.trigger('stateChanged', this.toState());
    }
  }

  async refreshToken(skipTrigger?: boolean) {
    this._lastRefresh = Date.now();
    const authSession = await this._auth.get();

    // console.log(`SystemSession: refreshToken ${authSession ? 'with token' : 'without token'}`);

    return authSession
      ? this.setToken(await this.fetchSystemToken(authSession.token), skipTrigger)
      : this.resetToken();
  }

  resetToken() {
    if (!this._data && !this._error) {
      return;
    }

    this._error = undefined;
    this._data = undefined;
    this._authToken = undefined;

    this.trigger('stateChanged', this.toState());
  }

  setToken(token: string, skipTrigger?: boolean) {
    if (token === this.token) {
      return;
    }

    const data = Object.freeze({
      claims: (jwtDecode as any)(token),
      token,
    });

    // if not explicitly defined, autodetermine skipTrigger
    if (skipTrigger === undefined) {
      const prevClaimState = this.getClaimState(this._data && this._data.claims);
      const newClaimState = this.getClaimState(data && data.claims);

      // skip if previous and next claims just differ in base claims
      skipTrigger = isEqual(prevClaimState, newClaimState);
    }

    this._data = Object.freeze({
      claims: (jwtDecode as any)(token),
      token,
    });
    this._error = undefined;

    if (!skipTrigger) {
      this.trigger('stateChanged', this.toState());
    }
  }

  getClaimState(obj: any): any {
    if (!obj) {
      return {};
    }

    return Object
      .entries(obj)
      .filter((kv) => !['exp','iat','iss','aud'].includes(kv[0]))
      .reduce((res, [key, value]) => ({
        ...res,
        [key]: value
      }), {});
  }

  toState(): SystemSessionState {
    return {
      error: this._error,
      init: () => this.init(),
      initialized: this._initialized,
      loading: this._loading,
      logout: () => this.logout(),
      user: this.token
        ? {
          authorization: () => this.authorization!,
          claims: this.claims!,
          isOwner: this.isOwner,
          token: this.token!,
        }
        : undefined,
      refresh: async () => {
        if (shouldRefresh(this.claims?.exp, this.lastRefresh)) {
          try {
            await this.refreshToken();
          } catch (e) {
            const error = e as {message: string};
            this.setError({ message: `failed to refresh token before making a request ${error.message}` });
          }
        }
      },
      region: getRegions().find((x: any) => x.isCurrent)?.region.toUpperCase()
    };
  }

  protected async fetchSystemToken(authAccessToken: string): Promise<string> {
    const response: Response = await fetch(this._systemTokenExchangeEndpoint, {
      body: JSON.stringify({}),
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authAccessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const result = await response.json();
    if (response.status !== 200 || !result.token) {
      throw new Error(result.message || `unexpected_http_status_code_${response.status}`);
    }

    return result.token;
  }
}

export const systemSession = new SystemSession({
  systemTokenExchangeEndpoint: appConfig.systemTokenExchangeEndpoint,
  autoRefresh: true,
});

// (window as any || global as any)["systemSession"] = systemSession;
