import * as Sentry from '@sentry/browser';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import { appConfig } from './appConfig';
import { AppWrapper } from './AppWrapper';

declare global {
  interface Window {
    sc: any;
  }
}

// announce software
console.log(
  `%cscreencloud/management-console-frontend%c version: %c${appConfig.version}`,
  `color: black; background: #fecf00; font-weight: bold;`,
  `font-weight: bold;`,
  ``,
);


// set up sentry
appConfig.sentry && Sentry.init(appConfig.sentry);

// render app
ReactDOM.render(<AppWrapper />, document.getElementById('root') as HTMLElement);

// unregister all service workers
// DO NOT REMOVE THIS BEFORE THE YEAR 2021
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      for (const registration of registrations) {
        registration.unregister().catch(() => undefined);
      }
    });
}
