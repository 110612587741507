
export interface SignagePathParts {
  prefix: string;
  key: string;
  page: string;
  params: string;
  path: string;
}

export function parsePathname(pathname: string = window.location.pathname): SignagePathParts {
  // extract from (/org/(5))(/(screens)/add)(?foo=bar)
  const [ , prefix = '', key = '0', path = '/', page = '/', params = ''] = pathname
    .match(/^(\/org\/([0-9]+))?((.*?)(\?.*)?)?$/) || [];
  return {
    key,
    page,
    params,
    path,
    prefix,
  };
}

export function shouldRefresh(expiresAt: number | undefined, lastRefresh: number) {
  // ensure number
  const exp = expiresAt || 0;

  // undefined or zero-ish expirations should never attempt a refresh
  if (!exp) {
    return false;
  }

  // expires in next 30 seconds
  const expiresSoon = (exp - 30) < (Date.now() / 1000);

  // refreshed in last 10 seconds?
  const refreshedRecently = lastRefresh
    ? (lastRefresh + (10 * 1000)) > Date.now()
    : false;

  // sessions has run out OR (runs out soon AND last refresh isn't too recent)
  return expiresSoon && !refreshedRecently;
}
