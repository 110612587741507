export interface IEvent<T> {
  data: T;
  type: string;
}

export type IEventHandler<T> = (event: IEvent<T>) => void;

export class EventEmitter<T_EVENTS = any> {
  protected _handlers: { [type: string]: IEventHandler<any>[] } = {};

  on<T_TYPE extends keyof T_EVENTS>(type: T_TYPE, cb: IEventHandler<T_EVENTS[T_TYPE]>) {
    if (typeof cb !== 'function') {
      throw new Error('event-callback must be a function');
    } else if (typeof type !== 'string' || !type) {
      throw new Error('event-type must be non-empty string');
    }

    // no duplicate subscriptions
    if (this._handlers[type] && this._handlers[type].includes(cb)) {
      return;
    }

    // add handler to collection
    this._handlers[type] = [
      ...(this._handlers[type] || []),
      cb,
    ];
  }

  off<T_TYPE extends keyof T_EVENTS>(type: T_TYPE, cb: IEventHandler<T_EVENTS[T_TYPE]>) {
    if (typeof cb !== 'function') {
      throw new Error('event-callback must be a function');
    } else if (typeof type !== 'string' || !type) {
      throw new Error('event-type must be non-empty string');
    }

    if (!this._handlers[type]) {
      return;
    }

    this._handlers[type] = this._handlers[type]
      .filter((h) => h !== cb);
  }

  protected trigger<T_TYPE extends keyof T_EVENTS>(type: T_TYPE, data: T_EVENTS[T_TYPE]) {
    console.log(`trigger `, type, data);
    (this._handlers[type as any] || []).forEach((cb) => {
      try {
        cb({ data, type: type as string });
      } catch (e) {
        console.log(`Error in ${type as string}-handler`, e);
      }
    });
  }
}
