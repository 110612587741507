import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger'; // todo: use it only in development build
import { withClientState } from 'apollo-link-state';
import { appConfig } from '../appConfig';

export function getApolloClient(options: { authorizationFn: () => Promise<string | undefined> }) {

  const cache = new InMemoryCache({
    addTypename: true,
    dataIdFromObject: (object: any) => (
      object.id
        ? `${object.__typename}_${object.id}`
        : null
    ),
  });

  const stateLink = withClientState({
    cache,
    defaults: {},
    resolvers: {},
  });

  const httpLink = new HttpLink({
    uri: appConfig.systemGraphqlEndpoint,
  });

  // adds network delay so that development feels as slow as worst case latency
  const slowNetworkSimulatorLink = new ApolloLink((operation, forward) => {
    if (!forward) {
      return null;
    }
    if (appConfig.reactEnvironment !== 'development') {
      return forward(operation);
    }
    return new Observable((observer) => {
      setTimeout(
        () => {
          forward(operation).subscribe(observer);
        },
        50 + Math.round((Math.random() * 50)),
      );
    });
  });

  const authLink = setContext(async (_, data) => {
    // const appstore = data.appstore
    const headers = data.headers;
    // get the authentication token from local storage if it exists
    const authorization = await options.authorizationFn();
    // return the headers to the context so httpLink can read them
    if (authorization && !(
      headers && headers.authorization
    )) {
      return { headers: { ...headers, authorization } };
    } else {
      return { headers };
    }
  });

  const client = new ApolloClient({
    cache,
    connectToDevTools: appConfig.reactEnvironment === 'development',
    link: ApolloLink.from([apolloLogger, stateLink, authLink, slowNetworkSimulatorLink, httpLink]),
  });

  client.onResetStore(() => Promise.resolve().then(stateLink.writeDefaults));

  return client;
}
